import { grey } from "@mui/material/colors";

const styles = {
  close: {
    fontSize: 14,
  },
  selectAll: {
    borderBottom: `1px solid ${grey[200]}`,
    py: 1,
    px: 3.2,
    width: "100%",
  },
};

export default styles;
