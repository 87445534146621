import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { AutogeneratedDraftsContext } from "contexts/AutogenerateDrafts";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { forwardRef, useContext } from "react";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import EmailButton from "components/EmailButton";
import { generateInitials } from "utils/string";
import { makeDeepCopy } from "utils/updateLocalState";
import { useCollection } from "utils/useCollection";
import { useDrafts } from "utils/useDrafts";

import styles from "./styles";

interface SelectedBrandProps {
  bentoBrand: BentoBrand;
  collectionId?: number;
  isBulkMode?: boolean;
  fromDeleteDraft?: boolean;
  deleteDraft?: () => void;
}

const SelectedBrand = forwardRef(
  (
    {
      bentoBrand,
      collectionId = -1,
      isBulkMode,
      fromDeleteDraft = false,
    }: SelectedBrandProps,
    ref,
  ) => {
    const { isDraftBrand } = useCollection();
    const { removeDraftFromList, removeDraftApiCall } = useDrafts();

    // Contexts
    const {
      selectAll,
      setSelectAll,
      handleRemoveDebounced,
      removeLoading,
      bulkBrands,
      setBulkBrands,
    } = useContext(SavedBrandContext);
    const { handleOpenQuickSendIndividual, quickSendBrandId } = useContext(
      QuickSendDrawerContext,
    );
    const { collectionsNames } = useContext(SavedBrandCollectionsContext);
    const { frozenIds } = useContext(AutogeneratedDraftsContext);

    const isSelected = quickSendBrandId === bentoBrand?.id;
    const isFrozen = frozenIds?.includes(Number(bentoBrand?.id));
    const isChecked = Boolean(
      bulkBrands?.find((x) => Number(x.id) === Number(bentoBrand.id)),
    );

    const handleDelete = async (e: CustomEvent) => {
      e.stopPropagation();

      removeDraftFromList(bentoBrand, collectionId);

      // When user remove drafts from the Popup Dialog
      if (fromDeleteDraft) {
        setBulkBrands((prev) => {
          const copy = makeDeepCopy(prev);
          const index = prev.findIndex(
            (b) => Number(b.id) === Number(bentoBrand.id),
          );
          if (index > -1) {
            copy[index]["hasOutreachDraft"] = false;
          }
          return copy;
        });
        await removeDraftApiCall(
          e,
          bentoBrand,
          "Write Me Drafts Dialog",
          false,
        );
      } else {
        handleRemoveDebounced(bentoBrand, collectionId);
      }
    };

    const handleSelect = () => {
      if (selectAll) {
        setSelectAll(false);
      }
      setBulkBrands((prev) => {
        const copy = makeDeepCopy(prev);
        const index = prev.findIndex(
          (b) => Number(b.id) === Number(bentoBrand.id),
        );
        if (index > -1) {
          copy.splice(index, 1);
        } else {
          copy.push(bentoBrand);
        }
        return copy;
      });
    };

    const clickOnBrand = () => {
      if (isFrozen) return;
      handleOpenQuickSendIndividual(
        bentoBrand?.id,
        "Favorites List",
        undefined,
        true,
      );
    };

    const goToInstagram = (e: CustomEvent) => {
      e.stopPropagation();
      window.open(
        `https://instagram.com/${bentoBrand.instagramUsername}`,
        "_blank",
      );
    };

    const renderLeftButton = () => {
      if (isFrozen) return <></>;
      if (isBulkMode) {
        return (
          <Box onClick={(e) => e.stopPropagation()}>
            <Checkbox
              onChange={handleSelect}
              disableRipple
              checked={isChecked}
              icon={
                <Box
                  sx={{ color: "green.main" }}
                  component="i"
                  className="fa-regular fa-square"
                />
              }
              checkedIcon={
                <Box
                  component="i"
                  className="fa-solid fa-square-check"
                  sx={{ color: "green.main" }}
                />
              }
            />
          </Box>
        );
      } else {
        return (
          <>
            {removeLoading === bentoBrand?.id ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton onClick={handleDelete} sx={styles.removeButton}>
                <Box component="i" className="fa-regular fa-circle-xmark" />
              </IconButton>
            )}
          </>
        );
      }
    };

    return (
      <Box
        ref={ref}
        sx={[styles.brandContainer, isSelected && styles.selected]}
        onClick={clickOnBrand}
        key={`cart-brand-${bentoBrand.id}`}
      >
        {renderLeftButton()}
        <Box sx={styles.brandImage}>
          {bentoBrand.logoUrl ? (
            <img
              src={bentoBrand.logoUrl}
              alt={`${bentoBrand.brandName} logo`}
            />
          ) : (
            <Avatar sx={styles.avatar}>
              {generateInitials(bentoBrand.brandName)}
            </Avatar>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={styles.brandName}>
            {bentoBrand.brandName}
            {!isFrozen &&
              (isDraftBrand(bentoBrand) || bentoBrand?.hasOutreachDraft) && (
                <>
                  ,{" "}
                  <Box component="span" sx={styles.draft}>
                    Draft{" "}
                  </Box>
                </>
              )}
            {isFrozen && <Box sx={styles.frozen}>Generating...</Box>}
          </Box>

          {bentoBrand.instagramUsername && (
            <Link target="_blank" onClick={goToInstagram} sx={{ fontSize: 12 }}>
              @{bentoBrand.instagramUsername}
            </Link>
          )}

          {bentoBrand?.savedBrandCollectionId && collectionId === 0 ? (
            <Typography variant="body2" sx={{ mt: 1 }}>
              In{" "}
              <strong>
                <em>{collectionsNames[bentoBrand?.savedBrandCollectionId]}</em>
              </strong>
            </Typography>
          ) : (
            <></>
          )}
        </Box>

        {!fromDeleteDraft && <EmailButton isFrozen={isFrozen} />}
      </Box>
    );
  },
);

export default SelectedBrand;
