import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Typography,
  capitalize,
  useMediaQuery,
} from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext, useState } from "react";
import { SubscriptionType } from "schemas/dashboard";
import { Plans, SubscriptionMessages } from "schemas/payments";
import theme from "styles/theme";

import { SubscriptionColor } from "utils/color";
import { formatEpochTimestamp } from "utils/time";
import { trackEvent } from "utils/tracking";

import CancelDialog from "./CancelDialog";
import ManageDialog from "./ManagePlanDialog";
import {
  disabledCurrentPlan,
  getCurrentPlanButton,
  getOtherPlanButton,
  getStatusChipLabel,
  getSubscriptionInformation,
} from "./helpers";
import styles from "./styles";

interface IProps {
  plan: Plans;
  loading: boolean;
  handleCheckout: (
    plan: Plans,
    flow?: string,
    openTarget?: "_self" | "_blank",
  ) => void;
  renewSubscription: (plan: Plans) => void;
}

export function Plan({
  loading,
  handleCheckout,
  renewSubscription,
  plan,
}: IProps) {
  const {
    subscription,
    plans,
    isProPlan,
    emailSents,
    brandRequestsMade,
    upgradeDialogSource,
    customizedMessagesDrafted,
  } = useContext(SubscriptionContext);
  const { discoverTab } = useContext(DiscoverViewContext);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileScreen =
    isMediumScreen || (isLargeScreen && discoverTab !== null);

  const { stripePlan, isCurrentPlan, price, description, resubscribe, renew } =
    getSubscriptionInformation(plan, plans, subscription);

  const [cancelDialog, setCancelDialog] = useState<boolean>(false);
  const [manageDialog, setManageDialog] = useState<boolean>(false);

  const backgroundColor = stripePlan?.status
    ? SubscriptionColor[stripePlan?.status]
    : "";

  // Do not show emailSentText if the user hit a limit, since that information is shown elsewhere
  const emailSentText = upgradeDialogSource !== "Hits Brand Request Limit" &&
    upgradeDialogSource !== "Hits Limit" && (
      <>
        <Divider sx={{ mb: 2 }} />
        <Typography color="textSecondary" paragraph component="div">
          You've sent{" "}
          <Box component="span" sx={{ color: "info.main" }}>
            <strong>{emailSents}</strong>
          </Box>{" "}
          emails this billing period. You can send{" "}
          <Box component="span" sx={{ color: "success.main" }}>
            <strong>{subscription?.remainingMessages}</strong>
          </Box>{" "}
          more
          {subscription && subscription.referralsBoost > 0 && (
            <> (including {subscription.referralsBoost} from referrals)</>
          )}
          .{" "}
          {subscription?.overrideSubscription && (
            <strong>
              To try out the platform, we have gifted you the Pro plan.
            </strong>
          )}
          <br />
          <br />
          You have made{" "}
          <Box component="span" sx={{ color: "info.main" }}>
            <strong>{brandRequestsMade}</strong>
          </Box>{" "}
          brand requests. You have{" "}
          <Box component="span" sx={{ color: "success.main" }}>
            <strong>{subscription?.remainingBrandRequests}</strong>
          </Box>{" "}
          remaining.
          <br />
          <br />
          You have used{" "}
          <Box component="span" sx={{ color: "info.main" }}>
            <strong>{customizedMessagesDrafted}</strong>
          </Box>{" "}
          customized AI messages. You have{" "}
          <Box component="span" sx={{ color: "success.main" }}>
            <strong>{subscription?.remainingChatMessages}</strong>
          </Box>{" "}
          remaining.
          <br />
          <br />
          {subscription && (
            <>
              Your plan's limits will reset on{" "}
              <strong>
                {formatEpochTimestamp({
                  epochTimestamp: subscription.billingCycle[1],
                  addYear: true,
                })}
              </strong>
              .{" "}
            </>
          )}
        </Typography>
      </>
    );

  const openCancel = () => {
    trackEvent(
      "Manage Subscription Dialog Cancel Subscription Button Pressed",
      {
        plan,
      },
    );
    setManageDialog(false);
    setCancelDialog(true);
  };

  const closeManageDialog = () => {
    trackEvent("Manage Subscription Dialog Closed", {
      plan,
    });
    setManageDialog(false);
  };

  const closeCancelDialog = () => {
    trackEvent("Cancel Subscription Dialog Closed", {
      plan,
    });
    setCancelDialog(false);
  };

  return (
    <Grid
      item
      xs={12}
      md={isMobileScreen ? 12 : 4}
      container
      direction="column"
    >
      <Grid item container xs="auto" sx={{ mb: 3 }}>
        <Grid item xs="auto" sx={{ mr: 1 }}>
          <Typography variant="h5">
            {capitalize(plan)}
            {isCurrentPlan && (
              <Chip
                size="small"
                sx={[styles.chip, { backgroundColor }]}
                label={getStatusChipLabel(subscription)}
              />
            )}
            <br />
            {price === 0 ? "$0" : `$${price}/month`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs sx={styles.textContainer}>
        <Typography color="textSecondary" sx={styles.planDescription}>
          {description}
        </Typography>
        {isCurrentPlan ? (
          <>
            {stripePlan?.status !== SubscriptionType.CANCELED && emailSentText}
            {subscription && subscription?.remainingMessages <= 0 ? (
              <Typography paragraph sx={styles.warningText}>
                Please upgrade to send more this month
              </Typography>
            ) : (
              <Typography paragraph sx={styles.warningText}>
                <i>
                  {stripePlan?.status &&
                    stripePlan?.status in SubscriptionMessages &&
                    SubscriptionMessages[stripePlan?.status]}
                  {stripePlan?.cancelAt &&
                    ` Your subscription is set to cancel on ${formatEpochTimestamp(
                      {
                        epochTimestamp: stripePlan.cancelAt,
                        addYear: true,
                      },
                    )}. Currently, all follow-ups and brand requests beyond your
                            cancellation date will be deleted.`}
                </i>
              </Typography>
            )}
          </>
        ) : (
          <>
            {stripePlan?.status === SubscriptionType.CANCELED &&
              plan === Plans.FREE &&
              emailSentText}
          </>
        )}
      </Grid>
      <Grid item xs="auto">
        {isCurrentPlan ? (
          <>
            <LoadingButton
              loading={resubscribe && loading}
              color="primary"
              variant={resubscribe || renew ? "contained" : "outlined"}
              fullWidth
              disabled={disabledCurrentPlan(plan, subscription)}
              onClick={() => {
                if (resubscribe) {
                  trackEvent("Resubscribe Subscription Button Pressed", {
                    plan,
                  });
                  handleCheckout(plan);
                } else if (renew) {
                  trackEvent("Renew Subscription Button Pressed", {
                    plan,
                  });
                  renewSubscription(plan);
                } else {
                  trackEvent("Manage Plan Button Pressed", {
                    plan,
                  });
                  setManageDialog(true);
                }
              }}
              disableElevation
            >
              {getCurrentPlanButton(plan, subscription)}
            </LoadingButton>
          </>
        ) : (
          <>
            {plan !== Plans.FREE && !subscription?.overrideSubscription && (
              <LoadingButton
                loading={loading}
                onClick={() => {
                  trackEvent(
                    `${getOtherPlanButton(plan, isProPlan)} Button Pressed`,
                    {
                      plan,
                    },
                  );
                  if (getOtherPlanButton(plan, isProPlan) === "Upgrade") {
                    handleCheckout(plan, "subscription_update");
                  } else {
                    handleCheckout(plan, "subscription_update", "_blank");
                  }
                }}
                color={
                  isProPlan && plan === Plans.STARTER ? "secondary" : "primary"
                }
                variant={
                  isProPlan && plan === Plans.STARTER ? "outlined" : "contained"
                }
                fullWidth
                disableElevation
              >
                {getOtherPlanButton(plan, isProPlan)}
              </LoadingButton>
            )}
          </>
        )}

        <ManageDialog
          loading={loading}
          open={manageDialog}
          openCancel={openCancel}
          onClose={closeManageDialog}
          handleCheckout={handleCheckout}
          plan={plan}
        />

        <CancelDialog
          loading={loading}
          open={cancelDialog}
          onClose={closeCancelDialog}
          handleCheckout={handleCheckout}
          plan={plan}
        />
      </Grid>
    </Grid>
  );
}
