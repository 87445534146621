import { Grid, useMediaQuery, useTheme } from "@mui/material";
import {
  AutogeneratedDraftsContext,
  TabPage,
} from "contexts/AutogenerateDrafts";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { QuickSendContext } from "contexts/QuickSend";
import {
  QuickSendDrawerContext,
  QuickSendTabView,
} from "contexts/QuickSendDrawer";
import { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";

import QuickSendIndividualDrawer from "features/Influencer/ContactList/QuickSendIndividualDrawer";
import { trackEvent } from "utils/tracking";
import { useDrafts } from "utils/useDrafts";
import { usePageSize } from "utils/usePageSize";

import DetailedBrandView from "../../Discover/DetailedBrandView";
import DraftsBrandList from "../BrandList";
import CollapsibleButton from "../CollapsibleButton";
import styles from "./styles";

const RecommendationsPage = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    brandDetailsId,
    handleOpenQuickSendIndividual,
    brandView,
    setBrandView,
    setView,
    setBrandDetailsId,
    closeDrawer,
  } = useContext(QuickSendDrawerContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { isTwoColumnView } = usePageSize();
  const { discoverTab, setDiscoverTab } = useContext(DiscoverViewContext);
  const { isPolling, pollForNewDrafts, fetchDrafts, savedBrandCollectionId } =
    useContext(AutogeneratedDraftsContext);
  const { tab } = useParams();
  const { getDrafts, getHitsLimit } = useDrafts();

  const drafts = getDrafts(tab);
  const hitsLimit = getHitsLimit(tab);

  const { outreachMessages } = useContext(QuickSendContext);
  const brandCollapsed =
    brandDetailsId < 0 || brandView === QuickSendTabView.COLLAPSE;

  const [hideBrandView, setHideBrandView] = useState(false);
  const [hideList, setHideList] = useState(false);
  const [endRef, isVisible] = useInView({
    rootMargin: "0px 0px",
  });

  const [fetchMoreLoading, setFetchMoreLoading] = useState<boolean>(false);

  const fetchMoreDrafts = async () => {
    if (!currentOrg?.id) {
      return;
    }

    setFetchMoreLoading(true);
    await fetchDrafts(tab as TabPage, savedBrandCollectionId);
    setFetchMoreLoading(false);
  };

  const toggleBrandInfoView = () => {
    trackEvent("Recommendations - Brand Info View Toggled");
    setDiscoverTab(null);
    setHideBrandView((prev) => {
      if (prev && brandCollapsed && drafts?.length > 0) {
        const brand = drafts?.[0]?.bentoBrand;
        setBrandDetailsId(brand.id);
        setBrandView(QuickSendTabView.EXPAND);
      }
      return !prev;
    });
  };

  const toggleListView = () => {
    trackEvent("Recommendations - List View Toggled");
    setHideList((prev) => !prev);
  };

  useEffect(() => {
    if (isVisible && !fetchMoreLoading && !hitsLimit) {
      trackEvent("Recommendations - List Scrolled To Bottom");
      fetchMoreDrafts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, fetchMoreLoading, currentOrg?.id]);

  useEffect(() => {
    if (!isTwoColumnView && !discoverTab) {
      setBrandView(QuickSendTabView.EXPAND);
      setView(QuickSendTabView.EXPAND);
    } else if (brandView !== QuickSendTabView.COLLAPSE) {
      setBrandView(QuickSendTabView.COLLAPSE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, isTwoColumnView]);

  useEffect(() => {
    if (discoverTab || brandCollapsed) {
      setHideBrandView(true);
    } else {
      setHideBrandView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, brandCollapsed, discoverTab]);

  const getPollingLogic = () => {
    if (tab === TabPage.bento_recommendations) {
      const currentDrafts = getDrafts(TabPage.bento_recommendations);
      if (currentDrafts?.length === 0) {
        pollForNewDrafts(tab);
        return;
      }
    }

    if (tab === TabPage.favorite_drafts && !isPolling) {
      const currentDrafts = getDrafts(TabPage.favorite_drafts);
      if (currentDrafts?.length === 0) {
        fetchDrafts(tab as TabPage);
        return;
      }
    }
  };

  useEffect(() => {
    getPollingLogic();
    // Close opened tabs so it doesn't open when user leave Recommendations page.
    return () => {
      closeDrawer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, currentOrg?.id]);

  useEffect(() => {
    // Open the drawer when the page is opened
    if (!isMobileScreen && drafts?.[0]?.bentoBrandId) {
      handleOpenQuickSendIndividual(
        drafts[0].bentoBrandId,
        "Autogenerated Drafts",
        undefined,
        !isTwoColumnView,
        drafts[0].bentoBrand,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drafts?.[0]?.bentoBrandId]);

  return (
    <Grid
      container
      sx={
        isMobileScreen ? {} : styles.whiteFixedBackGround(discoverTab !== null)
      }
    >
      {isMobileScreen ? (
        <Grid item xs={12}>
          <DraftsBrandList endRef={endRef} />
        </Grid>
      ) : (
        <>
          {!hideList && (
            <Grid
              item
              xs={12}
              md={3}
              sx={
                isMobileScreen
                  ? styles.columnContainerMobile
                  : styles.columnContainer
              }
              container
              flexDirection={"column"}
            >
              <DraftsBrandList endRef={endRef} />
            </Grid>
          )}
          {isTwoColumnView ? (
            <Grid
              container
              item
              xs
              md
              sx={[styles.columnContainer, { overflow: "hidden" }]}
            >
              {!brandCollapsed ? (
                <DetailedBrandView />
              ) : (
                <QuickSendIndividualDrawer />
              )}
            </Grid>
          ) : (
            <>
              <CollapsibleButton
                clickArrow={toggleListView}
                hideView={!hideList}
              />
              <Grid
                item
                xs
                sx={[styles.columnContainer, { overflowY: "hidden" }]}
              >
                <QuickSendIndividualDrawer />
              </Grid>

              <CollapsibleButton
                clickArrow={toggleBrandInfoView}
                hideView={hideBrandView}
              />

              {!brandCollapsed &&
                discoverTab === null &&
                !hideBrandView &&
                outreachMessages?.length > 0 && (
                  <Grid item xs={12} md={3.5} sx={[styles.columnContainer]}>
                    <DetailedBrandView />
                  </Grid>
                )}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default RecommendationsPage;
