import { grey } from "@mui/material/colors";

const styles = {
  brandListContainer: {
    overflowY: "scroll",
    flexGrow: 1,
    paddingBottom: "20px", // To leave space for the "Bento reloads message"
  },
  explanation: {
    backgroundColor: "secondary.light",
    padding: 1,
    fontSize: 12,
    marginBottom: { xs: 10, md: 0 },
  },
  subtitle: {
    p: 2,
    color: grey[600],
    fontStyle: "italic",
  },
  tab: {
    color: "black",
    textTransform: "none",
    fontSize: 12,
  },
  link: {
    textDecoration: "none",
    fontSize: 12,
    cursor: "pointer",
    fontWeight: "bold",
  },
};

export default styles;
