import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { Dispatch, SetStateAction, useContext } from "react";
import { CustomEvent } from "schemas/functions";

import { MAXIMUM_FOLLOWUP_ALLOWED } from "constants/templates";
import { Template } from "features/Influencer/ContactList/schema";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

interface Props {
  templates: Template[];
  setTemplates: Dispatch<SetStateAction<Template[]>>;
  setDisplayTemplateScreen: Dispatch<SetStateAction<boolean>>;
}

export default function TemplateScreen({
  templates,
  setTemplates,
  setDisplayTemplateScreen,
}: Props) {
  const {
    templates: allTemplates,
    outreachTemplates,
    followupTemplates,
  } = useContext(OutreachTemplatesContext);

  const filteredOutreachTemplates = outreachTemplates?.filter(
    (t) => !t.emailTemplateId,
  );
  const filteredFollowUps = followupTemplates?.filter(
    (t) => !t.emailTemplateId,
  );

  const handleChange = (e: CustomEvent, index: number) => {
    const { value } = e.target;
    setTemplates((prev) => {
      const copy = makeDeepCopy(prev);
      copy[index] = allTemplates?.find((x) => x.id === value);
      return copy;
    });
  };

  const selectedFollowups = templates?.slice(1, templates?.length);

  const addFollowUp = () => {
    trackEvent(
      "Favorites List - Add Follow Up Button Pressed on Customization Dialog",
    );
    setTemplates((prev) => [...prev, followupTemplates?.[0]]);
  };

  const removeFollowUp = (index: number) => {
    trackEvent("Favorites List - Remove Follow-Up on Customization Dialog");
    setTemplates((prev) => {
      const copy = makeDeepCopy(prev);
      copy.splice(index, 1);
      return copy;
    });
  };

  return (
    <>
      <DialogTitle>
        <IconButton sx={{ fontSize: 13, color: "black" }}>
          <Box
            component="i"
            className="fa-regular fa-arrow-left"
            onClick={() => setDisplayTemplateScreen(false)}
          />
        </IconButton>
        Select Templates
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>
          Pick template below and we will generate a list of drafts from that
          template.
        </Typography>

        <Grid container gap={1} alignItems="center">
          <Grid item xs>
            <FormControl fullWidth sx={styles.form}>
              <InputLabel>Select Outreach</InputLabel>
              <Select
                value={templates[0]?.id || null}
                label="Select Outreach"
                onChange={(e) => handleChange(e, 0)}
              >
                {filteredOutreachTemplates?.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} />
        </Grid>

        {selectedFollowups?.map((followup, index) => (
          <Grid container gap={1} alignItems="center">
            <Grid item xs>
              <FormControl fullWidth sx={styles.form}>
                <InputLabel>Select Follow-Up</InputLabel>
                <Select
                  label="Select Follow-Up"
                  onChange={(e) => handleChange(e, index + 1)}
                  value={followup?.id}
                >
                  {filteredFollowUps?.map((template) => (
                    <MenuItem key={template.id} value={template.id}>
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={1}>
              <IconButton
                sx={{ fontSize: 13, color: "black" }}
                onClick={() => removeFollowUp(index + 1)}
              >
                <Box component="i" className="fa-regular fa-trash" />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        {followupTemplates?.length > 0 &&
          templates?.length < MAXIMUM_FOLLOWUP_ALLOWED + 1 && (
            <Button
              disableElevation
              size="small"
              variant="contained"
              onClick={addFollowUp}
              sx={{ my: 2 }}
            >
              Add Follow Up +
            </Button>
          )}
      </DialogContent>
    </>
  );
}
