import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { BubbleMenu } from "@tiptap/react";
import { Editor as TipTapEditor } from "@tiptap/react";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { Dispatch, SetStateAction, useContext } from "react";
import { PreviewMessage } from "schemas/dashboard";
import { isOnPage, routes } from "schemas/routes";

import { LinkModal } from "components/Editor/LinkModal";
import MenuOptions from "components/Editor/Menu";
import { VariableModal } from "components/Editor/Variable/VariableModal";
import { AttachmentProps } from "components/Editor/quicksend";
import StyledButton from "components/Styled/Button";
import { HandleSendEmail } from "features/Influencer/ContactList/schema";
import { useDrafts } from "utils/useDrafts";
import { Url, useLinkEditor } from "utils/useLinkEditor";
import { usePageSize } from "utils/usePageSize";
import { useVariablEditor } from "utils/useVariablesEditor";

import CustomizerHelper from "../CustomizerHelper";
import QuickSendButtonGroup from "./ButtonGroup";
import ScheduledTime from "./ScheduledTime";
import styles from "./styles";

interface Props {
  handleSendEmail: HandleSendEmail;
  sendLoading: boolean;
  attachmentProps: AttachmentProps;
  preview: PreviewMessage;
  index: number;
  editor: TipTapEditor;
  setUpdateOrgProfile?: Dispatch<SetStateAction<boolean>>;
}

export default function BottomToolbar({
  handleSendEmail,
  sendLoading,
  editor,
  attachmentProps,
  preview,
  index,
  setUpdateOrgProfile,
}: Props) {
  const { isOnboardingPage } = usePageSize();
  const { quickSendBrandId, referredEmail } = useContext(
    QuickSendDrawerContext,
  );
  const isReferred = Boolean(referredEmail);

  const { openBottomToolBar, setOpenBottomToolBar } = useContext(
    QuickSendDrawerContext,
  );
  const { bentoBrand } = useContext(QuickSendContext);
  const { removeDraftApiCall } = useDrafts();

  const {
    modalIsOpen,
    url,
    setUrl,
    openModal,
    closeModal,
    saveLink,
    removeLink,
  } = useLinkEditor({ editor });

  const {
    variable,
    variableModalIsOpen,
    closeVariableModal,
    handleSaveVariable,
    openVariableFilledModal,
    deleteVariableFilled,
  } = useVariablEditor({ editor });

  const { openAttachmentManager, setSelectImageOpen } = attachmentProps;

  const noBrand = !bentoBrand?.id || bentoBrand?.id !== quickSendBrandId;

  const handleClick = () => {
    setOpenBottomToolBar((prev) => !prev);
  };

  const renderDeleteButton = () => {
    return (
      isOnPage(routes.recommendations) &&
      bentoBrand && (
        <Tooltip title="Remove brand">
          <IconButton
            sx={{ fontSize: 16 }}
            onClick={(e) => removeDraftApiCall(e, bentoBrand)}
          >
            <Box component="i" className="fa-regular fa-trash" />
          </IconButton>
        </Tooltip>
      )
    );
  };

  return (
    <Grid
      container
      item
      sx={styles.bottomBarAbsolute}
      alignItems="flex-end"
      gap={1}
      justifyContent={"space-between"}
    >
      {openBottomToolBar && (
        // Important: this prevent onBlur event from triggering on TipTap editor.
        <Grid container item xs={12}>
          <Box
            sx={{ boxShadow: 2, px: 1, background: "white" }}
            onMouseDown={(e) => e.preventDefault()}
          >
            {editor && (
              <MenuOptions
                hideLink={true}
                editor={editor}
                openModal={openModal}
                isBubble={true}
                openVariableFilledModal={openVariableFilledModal}
                handleDeleteVariable={deleteVariableFilled}
                fromQuickSend={true}
              />
            )}
          </Box>
        </Grid>
      )}

      <Grid
        container
        item
        xs={12}
        sx={styles.sendSection}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Grid container item xs alignItems="center" gap={0.5}>
          {editor && (
            <>
              <IconButton
                sx={[styles.iconButton, openBottomToolBar ? styles.open : {}]}
                onClick={handleClick}
                onMouseDown={(e) => e.preventDefault()}
              >
                <Box component="i" className="fa-thin fa-font" />
              </IconButton>
              <IconButton
                onClick={openModal}
                sx={[
                  styles.iconButton,
                  editor.isActive("link") ? styles.open : {},
                ]}
              >
                <Box component="i" className="fa-solid fa-link" />
              </IconButton>
              <IconButton
                onClick={openAttachmentManager}
                sx={styles.iconButton}
              >
                <Box component="i" className="fa-solid fa-paperclip" />
              </IconButton>
              <IconButton
                sx={[
                  styles.iconButton,
                  editor.isActive("custom-image") ? styles.open : {},
                ]}
                onClick={() => setSelectImageOpen(true)}
              >
                <Box component="i" className="fa-solid fa-image" />
              </IconButton>
            </>
          )}

          {!isReferred && bentoBrand && !isOnboardingPage && (
            <CustomizerHelper bentoBrand={bentoBrand} />
          )}
        </Grid>

        <Grid
          container
          item
          xs={4}
          alignItems="center"
          justifyContent="flex-end"
        >
          {renderDeleteButton()}
          <QuickSendButtonGroup
            handleSendEmail={handleSendEmail}
            sendLoading={sendLoading}
            noBrand={noBrand}
          />
        </Grid>
        <LinkModal
          url={url}
          closeModal={closeModal}
          open={modalIsOpen}
          onChangeUrl={(newUrl: Url) => setUrl(newUrl)}
          onSaveLink={saveLink}
          onRemoveLink={removeLink}
        />

        <VariableModal
          variable={variable}
          open={variableModalIsOpen}
          closeModal={closeVariableModal}
          onSave={handleSaveVariable}
          setUpdateOrgProfile={setUpdateOrgProfile}
        />

        <BubbleMenu
          tippyOptions={{ duration: 50, zIndex: 1201, placement: "bottom" }}
          editor={editor}
          shouldShow={({ editor, from, to }) => {
            // only show the bubble menu for links.
            return from === to && editor.isActive("link");
          }}
        >
          <StyledButton
            onClick={openModal}
            onMouseDown={(e) => e.preventDefault()}
          >
            Edit Link{" "}
            <Box component="i" className="fa-regular fa-pen" sx={{ ml: 1 }} />
          </StyledButton>
        </BubbleMenu>

        <Grid item xs={12}>
          <ScheduledTime preview={preview} index={index} />
        </Grid>
      </Grid>
    </Grid>
  );
}
