import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { SavedBrandContext } from "contexts/SavedBrand";
import { useContext, useEffect } from "react";
import { BentoBrand, SavedBrandCollection } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface Props {
  selectedCollection: SavedBrandCollection | null;
  toggleBulkMode: () => void;
  getTotal: (collectionId: number) => JSX.Element;
  editCollection: () => void;
  isBulkMode: boolean;
  displayedBrands: BentoBrand[];
}

const ListHeader = ({
  selectedCollection,
  toggleBulkMode,
  getTotal,
  editCollection,
  isBulkMode,
  displayedBrands,
}: Props) => {
  const { bulkBrands, setBulkBrands, selectAll, setSelectAll } =
    useContext(SavedBrandContext);

  const handleSelectAll = (e: CustomEvent) => {
    trackEvent("Favorites List - Select All Toggled", {
      "Collection ID": selectedCollection?.id,
    });
    const { checked } = e.target;
    if (checked) {
      setBulkBrands(displayedBrands);
    } else {
      setBulkBrands([]);
    }
    setSelectAll(checked);
  };

  useEffect(() => {
    if (
      bulkBrands?.length === displayedBrands?.length &&
      !selectAll &&
      bulkBrands?.length > 0
    ) {
      setSelectAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkBrands?.length]);

  return selectedCollection ? (
    <Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems={"center"}
        sx={{ pl: 2, pr: 1, py: 1 }}
      >
        <Box>
          {selectedCollection?.name} {getTotal(selectedCollection.id)}
          {selectedCollection?.id && selectedCollection?.id > 0 ? (
            <Tooltip title="Edit Collection">
              <IconButton sx={styles.close} onClick={editCollection}>
                <Box component="i" className="fa-regular fa-edit" />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </Box>

        <Box>
          <Button
            size="small"
            onClick={toggleBulkMode}
            color={isBulkMode ? "primary" : "secondary"}
          >
            Select {bulkBrands?.length > 0 ? `(${bulkBrands?.length})` : ""}
          </Button>
        </Box>
      </Grid>

      {isBulkMode && (
        <Grid sx={styles.selectAll}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleSelectAll}
                checked={selectAll}
                disableRipple
                icon={
                  <Box
                    sx={{ color: "green.main" }}
                    component="i"
                    className="fa-regular fa-square"
                  />
                }
                checkedIcon={
                  <Box
                    component="i"
                    className="fa-solid fa-square-minus"
                    sx={{ color: "green.main" }}
                  />
                }
              />
            }
            label={<Box sx={{ ml: 1 }}>Select All</Box>}
          />
        </Grid>
      )}
    </Box>
  ) : (
    <></>
  );
};

export default ListHeader;
