import { grey } from "@mui/material/colors";

const styles = {
  moveTo: {
    px: 2,
    py: 1,
    borderTop: `1px solid ${grey[200]}`,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
    fontSize: 12,
  },
};

export default styles;
