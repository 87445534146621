import {
  Box,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AutogeneratedDraftsContext,
  TabPage,
} from "contexts/AutogenerateDrafts";
import { useContext } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { OutreachDraft } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { routes } from "schemas/routes";
import { HEART_COLOR } from "styles/oneOffColor";

import { useDrafts } from "utils/useDrafts";

import PickCollectionDropdown from "./PickCollectionDropdown";
import SelectedBrand from "./SelectedBrand";
import styles from "./styles";

const REF_OFFSET = 5;

interface Props {
  endRef: any;
}

const DraftsBrandList = ({ endRef }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { tab } = useParams();
  const { isPolling, fetchDrafts, frozenIds } = useContext(
    AutogeneratedDraftsContext,
  );
  const { getDrafts } = useDrafts();
  const drafts = getDrafts(tab);

  const handleChange = (e: CustomEvent, value: TabPage) => {
    fetchDrafts(value);
    navigate(`/${routes.recommendations}/${value}${location.search}`);
  };

  return (
    <>
      <Grid item xs sx={isMobileScreen ? {} : styles.brandListContainer}>
        <Box>
          <Tabs value={tab} onChange={handleChange}>
            <Tab
              sx={styles.tab}
              value={TabPage.favorite_drafts}
              label={
                <Box component="span">
                  Saved{" "}
                  <Box
                    component="i"
                    sx={{ color: HEART_COLOR, ml: 0.5 }}
                    className="fa-solid fa-bookmark"
                  />
                </Box>
              }
            />
            <Tab
              sx={styles.tab}
              value={TabPage.bento_recommendations}
              label="Recommendations"
            />
          </Tabs>

          {tab === TabPage.favorite_drafts ? (
            <PickCollectionDropdown />
          ) : (
            <Typography sx={styles.subtitle} variant="body2">
              20 personalized email drafts to brands you'll love, all the time.
              Want different recommendations? Update your{" "}
              <Link to={`/${routes.profile}`}>profile</Link>
            </Typography>
          )}
        </Box>

        {drafts.map((draft: OutreachDraft, index: number) => (
          <SelectedBrand
            key={draft.bentoBrand.id}
            ref={drafts.length - REF_OFFSET === index ? endRef : null}
            bentoBrand={draft.bentoBrand}
            collectionId={draft?.savedBrandCollectionId}
          />
        ))}

        {isPolling && (
          <Grid sx={{ px: 2 }}>
            {Array.from({ length: frozenIds?.length || 10 }, (x, i) => (
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={80}
                sx={{ my: 1 }}
                key={i}
              />
            ))}
          </Grid>
        )}
        {!isPolling && drafts?.length === 0 && (
          <Grid sx={{ px: 2 }}>
            No brands with autogenerated drafts on this list.
          </Grid>
        )}
      </Grid>
      {drafts?.length > 0 && tab === TabPage.bento_recommendations && (
        <Grid item sx={styles.explanation}>
          Bento reloads your list with a new draft every time you send an email
          from here.
        </Grid>
      )}
    </>
  );
};

export default DraftsBrandList;
