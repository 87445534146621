import { Avatar, Box, CircularProgress, IconButton, Link } from "@mui/material";
import { AutogeneratedDraftsContext } from "contexts/AutogenerateDrafts";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { forwardRef, useContext } from "react";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { generateInitials } from "utils/string";
import { useCollection } from "utils/useCollection";
import { useDrafts } from "utils/useDrafts";
import { usePageSize } from "utils/usePageSize";

import styles from "./styles";

interface SelectedBrandProps {
  bentoBrand: BentoBrand;
  collectionId?: number;
}

const SelectedBrand = forwardRef(
  ({ bentoBrand, collectionId }: SelectedBrandProps, ref) => {
    const { removeDraftApiCall } = useDrafts();
    const { removeDraftFromCollection } = useCollection();
    const { removeLoading } = useContext(AutogeneratedDraftsContext);
    const { isTwoColumnView } = usePageSize();
    const { quickSendBrandId, handleOpenQuickSendIndividual } = useContext(
      QuickSendDrawerContext,
    );

    const goToInstagram = (e: CustomEvent) => {
      e.stopPropagation();
      window.open(
        `https://instagram.com/${bentoBrand.instagramUsername}`,
        "_blank",
      );
    };

    const clickOnCard = () => {
      handleOpenQuickSendIndividual(
        bentoBrand?.id,
        "Autogenerated Drafts",
        undefined,
        !isTwoColumnView,
        bentoBrand,
      );
    };

    const handleClickDelete = async (e: CustomEvent) => {
      await removeDraftApiCall(e, bentoBrand);
      if (collectionId !== undefined) {
        removeDraftFromCollection(collectionId, bentoBrand);
      }
    };

    const isSelected = quickSendBrandId === bentoBrand?.id;

    return (
      <Box
        ref={ref}
        sx={[styles.brandContainer, isSelected && styles.selected]}
        onClick={clickOnCard}
        key={`cart-brand-${bentoBrand.id}`}
      >
        {removeLoading === bentoBrand?.id ? (
          <CircularProgress size={20} />
        ) : (
          <IconButton onClick={handleClickDelete} sx={styles.removeButton}>
            <Box component="i" className="fa-regular fa-circle-xmark" />
          </IconButton>
        )}
        <Box sx={styles.brandImage}>
          {bentoBrand.logoUrl ? (
            <img
              src={bentoBrand.logoUrl}
              alt={`${bentoBrand.brandName} logo`}
            />
          ) : (
            <Avatar sx={styles.avatar}>
              {generateInitials(bentoBrand.brandName)}
            </Avatar>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={styles.brandName}>
            {bentoBrand.brandName}
            <>
              ,{" "}
              <Box component="span" sx={styles.draft}>
                Draft{" "}
              </Box>
            </>
          </Box>

          {bentoBrand.instagramUsername && (
            <Link target="_blank" onClick={goToInstagram} sx={{ fontSize: 12 }}>
              @{bentoBrand.instagramUsername}
            </Link>
          )}
        </Box>
      </Box>
    );
  },
);

export default SelectedBrand;
