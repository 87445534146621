import { useAuth } from "@clerk/clerk-react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import {
  AutogeneratedDraftsContext,
  TabPage,
} from "contexts/AutogenerateDrafts";
import { OrganizationUserContext } from "contexts/Organization";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { SubscriptionContext } from "contexts/Subscription";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BentoBrand } from "schemas/dashboard";
import { routes } from "schemas/routes";

import { MAXIMUM_BULK_AI_MESSAGES_ALLOWED } from "constants/templates";
import {
  Template,
  TemplateContentType,
} from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";
import { useCollection } from "utils/useCollection";

import SelectedBrand from "../../BrandList/SelectedBrand";
import TemplateScreen from "./TemplateScreen";
import styles from "./styles";

enum CustomizationOption {
  template = "template",
  customized = "customized",
}

interface CustomizationDialogProps {
  open: boolean;
  handleClose: () => void;
  setIsBulkMode: Dispatch<SetStateAction<boolean>>;
}

export default function CustomizationDialog({
  open,
  handleClose,
  setIsBulkMode,
}: CustomizationDialogProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const navigate = useNavigate();

  const { addDraftToCollection } = useCollection();
  const { pollForNewDrafts, setFrozenIds } = useContext(
    AutogeneratedDraftsContext,
  );
  const { setUpgradeDialogSource, subscription, setSubscription } =
    useContext(SubscriptionContext);
  const { setErrorAlert, setAlert } = useContext(AlertContext);
  const { selectedCollection } = useContext(SavedBrandCollectionsContext);
  const { bulkBrands, setBulkBrands, setSelectAll } =
    useContext(SavedBrandContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();

  const [loading, setLoading] = useState(false);
  const [customizationOption, setCustomizationOption] =
    useState<CustomizationOption | null>(null);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [displayTemplateScreen, setDisplayTemplateScreen] = useState(false);

  const brandsWithDrafts = bulkBrands?.filter((x) => x.hasOutreachDraft);

  const selectOption = (option: CustomizationOption) => {
    trackEvent("Favorites List - Customization Option Selected", { option });
    setCustomizationOption(option);
  };

  const close = () => {
    handleClose();
    setCustomizationOption(null);
    setDisplayTemplateScreen(false);
    setIsBulkMode(false);
    setBulkBrands([]);
    setSelectAll(false);
  };

  const handleConfirm = async () => {
    if (
      customizationOption === CustomizationOption.template &&
      !displayTemplateScreen
    ) {
      setDisplayTemplateScreen(true);
      return;
    }

    if (
      (customizationOption === CustomizationOption.template &&
        templates?.length === 0) ||
      templates?.[0]?.contentType !== TemplateContentType.OUTREACH
    ) {
      setAlert("Please select one outreach template", "warning");
      return;
    }

    if (!bulkBrands) return;
    let bentoBrands = makeDeepCopy(bulkBrands)?.filter(
      (x: BentoBrand) => !x.hasOutreachDraft,
    );
    if (
      customizationOption === CustomizationOption.customized &&
      bentoBrands?.length > MAXIMUM_BULK_AI_MESSAGES_ALLOWED
    ) {
      bentoBrands = bentoBrands?.splice(0, MAXIMUM_BULK_AI_MESSAGES_ALLOWED);
    }

    // Check Paywall
    if (
      subscription?.remainingChatMessages &&
      bentoBrands?.length > subscription?.remainingChatMessages
    ) {
      setAlert("Please upgrade to use this feature.", "warning");
      setUpgradeDialogSource("Hits Personalized AI Messages Limit");
      return;
    }

    for (const brand of bentoBrands) {
      addDraftToCollection(brand);
    }

    if (bentoBrands?.length === 0) {
      setAlert("No drafts generated", "success");
      close();
      return;
    }

    try {
      // Freeze BentoBrand so user cannot delete or email when drafts are generating.
      const bentoBrandIds = bentoBrands?.map((b: BentoBrand) => b.id);
      setFrozenIds(bentoBrandIds);
      setLoading(true);

      const draftBrands = [];
      for (const brand of bentoBrands) {
        draftBrands.push({
          id: Number(brand.id),
          savedBrandCollectionId:
            brand.savedBrandCollectionId > 0
              ? brand.savedBrandCollectionId
              : null,
        });
      }

      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/saved-brand-collections/generate-drafts`,
        "POST",
        {},
        {
          bentoBrands: draftBrands,
          option: customizationOption,
          templates,
        },
      );
      if (customizationOption === CustomizationOption.customized) {
        setAlert(
          `Generating ${bentoBrandIds?.length} drafts for you. This might take a couple minutes.`,
          "success",
        );
      } else {
        setAlert(
          "Successfully created drafts based on your templates.",
          "success",
        );
      }
      pollForNewDrafts(
        TabPage.favorite_drafts,
        selectedCollection?.id,
        bentoBrandIds,
      );
      navigate(`/${routes.recommendationsSaved}${location.search}`);
      if (customizationOption === CustomizationOption.customized) {
        setSubscription((prev) => {
          if (prev) {
            prev["remainingChatMessages"] = Math.max(
              (prev?.remainingChatMessages || 0) - draftBrands?.length,
              0,
            );
          }
          return prev;
        });
      }
      close();
    } catch (error) {
      if (error?.message.includes("upgrade your account")) {
        setUpgradeDialogSource("Hits Personalized AI Messages Limit");
        setFrozenIds([]);
      }
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  const mainScreen = (
    <>
      <DialogTitle>Select Customization Level</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>Pick an option below.</Typography>
        <ButtonBase
          sx={[
            styles.button,
            customizationOption === CustomizationOption.template
              ? styles.active
              : {},
          ]}
          onClick={() => selectOption(CustomizationOption.template)}
        >
          No customization, just fill in my template.
        </ButtonBase>

        <ButtonBase
          sx={[
            styles.button,
            customizationOption === CustomizationOption.customized
              ? styles.active
              : {},
          ]}
          onClick={() => selectOption(CustomizationOption.customized)}
        >
          Customize my email ({MAXIMUM_BULK_AI_MESSAGES_ALLOWED} emails max)
        </ButtonBase>

        {bulkBrands?.length > MAXIMUM_BULK_AI_MESSAGES_ALLOWED &&
          customizationOption === CustomizationOption.customized && (
            <Typography variant="body2" textAlign="center" sx={{ mt: 1 }}>
              <em>
                You select {bulkBrands?.length} brands. Because this feature is
                limited to only {MAXIMUM_BULK_AI_MESSAGES_ALLOWED} brands per
                session, we will generate emails for only the first{" "}
                {MAXIMUM_BULK_AI_MESSAGES_ALLOWED} brands in your list.
              </em>
            </Typography>
          )}

        {brandsWithDrafts?.length > 0 && (
          <>
            <Typography sx={{ my: 2 }}>
              We noticed these brands already have a draft attached. Please
              remove the current draft to generate a new one.
            </Typography>

            {brandsWithDrafts?.map((brand) => (
              <SelectedBrand
                bentoBrand={brand}
                fromDeleteDraft={true}
                collectionId={selectedCollection?.id}
              />
            ))}
          </>
        )}
      </DialogContent>
    </>
  );

  const renderActionText = () => {
    if (
      !displayTemplateScreen &&
      customizationOption === CustomizationOption.template
    ) {
      return "Next";
    }
    return "Confirm";
  };

  return (
    <Dialog open={open} onClose={close} fullScreen={isMobileScreen}>
      {displayTemplateScreen ? (
        <TemplateScreen
          setDisplayTemplateScreen={setDisplayTemplateScreen}
          templates={templates}
          setTemplates={setTemplates}
        />
      ) : (
        mainScreen
      )}
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>

        <LoadingButton
          onClick={handleConfirm}
          disabled={customizationOption === null}
          loading={loading}
        >
          {renderActionText()}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
