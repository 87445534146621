import {
  ContactProperty,
  OutreachContactSmartInteraction,
  OutreachContactSmartStatus,
  PropertyType,
} from "schemas/dashboard";

export type ContactView = {
  id: number;
  name: string;
  contactViewProperties: ContactViewProperty[];
};

export type ContactViewProperty = {
  id?: number;
  contactViewId: number;
  contactPropertyId?: number;
  contactPropertyName?: string;
  displayOrder?: number;
  sortDirection?: PropertySortDirection;
  sortPriority?: number;
  contactProperty?: ContactProperty;
  filters: PropertyFilter[];
};

export const ColumnIcons: { [key in PropertyType]: string } = {
  [PropertyType.attachment]: "fa-paperclip",
  [PropertyType.checkbox]: "fa-square-check",
  [PropertyType.number]: "fa-hashtag",
  [PropertyType.singleSelect]: "fa-circle-check",
  [PropertyType.multipleSelect]: "fa-list-check",
  [PropertyType.shortText]: "fa-font",
  [PropertyType.longText]: "fa-quote-left",
  [PropertyType.date]: "fa-calendar",
  [PropertyType.dateTime]: "fa-clock",
};

export const NotEditableFields = [
  "smart_interactions",
  "smart_status",
  "email",
  "last_gmail_message_date",
];

export type SelectOption = {
  color: string;
  id?: string;
  name: string;
  automatedName?: string;
};

export enum PropertySortDirection {
  asc = "asc",
  desc = "desc",
}

export type EditRow = {
  property: ContactViewProperty | null;
  index: number;
};

export enum BuiltInContactPropertyNames {
  last_gmail_message_date = "last_gmail_message_date",
  is_important = "is_important",
  contact_name = "contact_name",
  contact_title = "contact_title",
  email = "email",
  email_thread = "email_thread",
  brand_name = "brand_name",
  smart_task = "smart_task",
  latest_sequence_status = "latest_sequence_status",
  latest_outreach_template = "latest_outreach_template",
  last_email_open_count = "last_email_open_count",
  actions = "actions",
}

export const SELECT_COLOR_OPTIONS = [
  "#C5B9DE",
  "#D9E8FF",
  "#F6E8B6",
  "#FFF3EC",
  "#F3DFFD",
  "#A7F1C9",
  "#F0F0F0",
  "#EDEAD8",
  "#DDDDDD",
  "#FFDAB8",
];

export const BuiltInFilters: { [key: string]: any } = {
  is_important: [true, false],
  latest_sequence_status: [
    "scheduled",
    "in_progress",
    "complete",
    "bounced",
    "responded",
  ],
  last_email_open_count: ["opened", "multiple_opens"],
  smart_status: [
    ...Object.values(OutreachContactSmartStatus),
    "sent_follow_up_1",
    "sent_follow_up_2",
    "sent_follow_up_3",
    "sent_follow_up_4",
    "sent_follow_up_5",
  ],
  smart_interactions: Object.values(OutreachContactSmartInteraction),
};

export const FiltersType = [
  PropertyType.checkbox,
  PropertyType.multipleSelect,
  PropertyType.singleSelect,
];

export const CustomFilterColumns: BuiltInContactPropertyNames[] = [
  BuiltInContactPropertyNames.latest_sequence_status,
  BuiltInContactPropertyNames.last_email_open_count,
];

export enum FilterType {
  selection = "selection",
  customBuiltIn = "custom_built_in",
}

export type PropertyFilter = {
  values: string[];
  type: FilterType;
};

export const EMAIL_COLUMN = "email";
export const BRAND_NAME_COLUMN = "brand_name";

export type ChangedFieldMap = {
  [key: string]: string[];
};
