import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { OutreachDraft } from "schemas/dashboard";
import { Map } from "schemas/functions";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import { OrganizationUserContext } from "./Organization";

export enum TabPage {
  favorite_drafts = "saved",
  bento_recommendations = "bento",
}

export type DraftsMap = {
  [key: string]: {
    drafts: OutreachDraft[];
    cursor: number[] | null;
    hitsLimit: boolean;
  };
};

interface AutogeneratedDraftsContextInterface {
  removeLoading: number;
  fetchDrafts: (
    tab: TabPage,
    collectionId?: number,
    bentoBrandIds?: number[],
  ) => Promise<boolean>;
  pollForNewDrafts: (
    tab: TabPage,
    collectionId?: number,
    bentoBrandIds?: number[],
  ) => void;
  setRemoveLoading: Dispatch<SetStateAction<number>>;
  isPolling: boolean;
  setSavedBrandCollectionId: Dispatch<SetStateAction<number>>;
  savedBrandCollectionId: number;
  draftsMap: DraftsMap;
  setDraftsMap: Dispatch<SetStateAction<DraftsMap>>;
  frozenIds: number[];
  setFrozenIds: Dispatch<SetStateAction<number[]>>;
  brandsToTab: Map;
  setBrandsToTab: Dispatch<SetStateAction<Map>>;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  removeLoading: -1,
  fetchDrafts: defaultContextMissingFunction,
  pollForNewDrafts: defaultContextMissingFunction,
  setRemoveLoading: defaultContextMissingFunction,
  isPolling: false,
  savedBrandCollectionId: 0,
  setSavedBrandCollectionId: defaultContextMissingFunction,
  draftsMap: {},
  setDraftsMap: defaultContextMissingFunction,
  frozenIds: [],
  setFrozenIds: defaultContextMissingFunction,
  brandsToTab: {},
  setBrandsToTab: defaultContextMissingFunction,
};

const AutogeneratedDraftsContext =
  createContext<AutogeneratedDraftsContextInterface>(defaultInterface);

interface AutogeneratedDraftsProviderProps {
  children: React.ReactNode;
}

const RESULTS_PER_PAGE = 20;

const AutogeneratedDraftsProvider = ({
  children,
}: AutogeneratedDraftsProviderProps) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);

  const [draftsMap, setDraftsMap] = useState<DraftsMap>({});
  const [isPolling, setIsPolling] = useState(false);
  const [removeLoading, setRemoveLoading] = useState<number>(-1);
  const [savedBrandCollectionId, setSavedBrandCollectionId] =
    useState<number>(0);
  const [frozenIds, setFrozenIds] = useState<number[]>([]);
  const [brandsToTab, setBrandsToTab] = useState<Map>([]);

  const tabFromPage = window.location.href?.includes(TabPage.favorite_drafts)
    ? TabPage.favorite_drafts
    : TabPage.bento_recommendations;

  const fetchDrafts = async (
    tab: TabPage,
    collectionId?: number,
    bentoBrandIds?: number[],
  ): Promise<boolean> => {
    if (!currentOrg) return false;
    try {
      let url = `/api/organization/${currentOrg?.id}/outreach-drafts/recommendations-page?per_page=${RESULTS_PER_PAGE}`;
      const cursor = getCursor(tab, collectionId);
      if (cursor) {
        url += `&cursor=${JSON.stringify(cursor)}`;
      }
      if (tab === TabPage.favorite_drafts) {
        url += `&is_from_saved_brand=${true}`;
        if (collectionId) {
          setSavedBrandCollectionId(collectionId);
          url += `&saved_brand_collection_id=${collectionId}`;
        }
      } else {
        url += `&is_autogenerated=${true}&is_from_saved_brand=${false}`;
      }
      const res = await fetcherAuth(getToken, url);
      if (res.outreachDrafts?.length > 0) {
        _updateCollection(tab, res.outreachDrafts, false, collectionId);
        _updateBrandsMapping(res.outreachDrafts, tab);
        // End polling after all drafts are created for user from SavedBrandCollection.
        if (bentoBrandIds && bentoBrandIds?.length > 0) {
          setFrozenIds([]);
          const ids = res.outreachDrafts?.map((x: OutreachDraft) =>
            Number(x.bentoBrandId),
          );
          return bentoBrandIds.every((id) => ids.includes(id));
        }
        return true;
      } else {
        _updateCollection(tab, [], true, collectionId);
        return false;
      }
    } catch (error) {
      setFrozenIds([]);
      return false;
    }
  };

  let intervalId: any = null;
  let timeoutId: any = null;

  const clear = (intervalId: any, timeoutId: any) => {
    clearInterval(intervalId);
    clearTimeout(timeoutId);
    setIsPolling(false);
  };

  const pollForNewDrafts = async (
    tab: TabPage,
    collectionId?: number,
    bentoBrandIds?: number[],
  ) => {
    if (isPolling) return;
    setIsPolling(true);
    const foundResult = await fetchDrafts(tab, collectionId, bentoBrandIds);
    if (!foundResult) {
      intervalId = setInterval(async () => {
        // If result is found
        const foundResult = await fetchDrafts(tab, collectionId, bentoBrandIds);
        if (foundResult) {
          clear(intervalId, timeoutId);
        }
      }, 5000);
      // Poll every 5 seconds for 1 minute. If no new drafts are found nothing will happen on FE.
      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        setIsPolling(false);
      }, 30000);
    } else {
      setIsPolling(false);
    }
  };

  const getCursor = (
    tab: TabPage | string | undefined,
    collectionId?: number,
  ): number[] | null => {
    const key = _retrieveKey(tab, collectionId);
    return draftsMap[key]?.cursor;
  };

  const _retrieveKey = (
    tab: TabPage | string | undefined,
    collectionId: number | undefined,
  ) => {
    let key: string = tab || TabPage.bento_recommendations;
    if (tab === TabPage.favorite_drafts) {
      if (collectionId !== undefined) {
        key = `${tab}_${collectionId}`;
      } else {
        key = `${tab}_${savedBrandCollectionId}`;
      }
    }
    return key;
  };

  const _updateCollection = (
    tab: TabPage,
    drafts: OutreachDraft[],
    hitsLimit: boolean,
    collectionId?: number,
  ) => {
    const key = _retrieveKey(tab, collectionId);

    setDraftsMap((prev) => {
      const copy = makeDeepCopy(prev);
      const lastDraft = drafts?.[drafts?.length - 1];
      if (!(key in copy)) {
        copy[key] = {
          hitsLimit,
          cursor: lastDraft ? [lastDraft?.createdAt, lastDraft?.id] : null,
          drafts,
        };
      } else {
        const outreachDrafts = _getDrafts(copy[key]["drafts"], drafts);
        const lastDraft = outreachDrafts?.[outreachDrafts?.length - 1];
        copy[key]["drafts"] = outreachDrafts;
        copy[key]["hitsLimit"] = hitsLimit;
        copy[key]["cursor"] = lastDraft
          ? [lastDraft?.createdAt, lastDraft?.id]
          : null;
      }
      return copy;
    });
  };

  const _updateBrandsMapping = (drafts: OutreachDraft[], tab: string) => {
    const copy = makeDeepCopy(brandsToTab);
    for (const draft of drafts) {
      const key = draft.bentoBrandId;
      if (tab === TabPage.bento_recommendations) {
        copy[key] = tab;
      } else if (draft?.savedBrandCollectionId) {
        copy[key] =
          `${TabPage.favorite_drafts}_${draft?.savedBrandCollectionId}`;
      } else {
        copy[key] = `${TabPage.favorite_drafts}_0`;
      }
    }
    setBrandsToTab(copy);
  };

  const _getDrafts = (
    currentDrafts: OutreachDraft[],
    newDrafts: OutreachDraft[],
  ) => {
    const ids = currentDrafts.map((draft: OutreachDraft) =>
      Number(draft.bentoBrandId),
    );
    const filtered = newDrafts?.filter(
      (x) => !ids?.includes(Number(x.bentoBrandId)),
    );
    return [...currentDrafts, ...filtered];
  };

  useEffect(() => {
    if (isPolling && tabFromPage === TabPage.favorite_drafts) {
      clear(intervalId, timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabFromPage]);

  return (
    <AutogeneratedDraftsContext.Provider
      value={{
        draftsMap,
        setDraftsMap,
        pollForNewDrafts,
        fetchDrafts,
        removeLoading,
        setRemoveLoading,
        isPolling,
        savedBrandCollectionId,
        setSavedBrandCollectionId,
        frozenIds,
        setFrozenIds,
        brandsToTab,
        setBrandsToTab,
      }}
    >
      {children}
    </AutogeneratedDraftsContext.Provider>
  );
};

export { AutogeneratedDraftsProvider, AutogeneratedDraftsContext };
