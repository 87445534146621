import { Button, Grid } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { useContext } from "react";
import { OutreachContact, OutreachContactStatus } from "schemas/dashboard";
import { Map } from "schemas/functions";

import MoveBrandToButton from "./MoveBrandToButton";
import { buttonStyles as styles } from "./styles";

interface Props {
  outreachContact: OutreachContact;
  goToThread: (
    url: string,
    name: string,
    openBlank?: boolean,
    properties?: Map,
  ) => void;
}

const TableAction = ({ outreachContact, goToThread }: Props) => {
  const { selectedView } = useContext(ContactViewContext);
  const { handleOpenQuickSendIndividual } = useContext(QuickSendDrawerContext);

  const isScheduled =
    !outreachContact.latestSequenceSentCount &&
    outreachContact.latestSequenceNextSendAt;

  const inProgress =
    !!outreachContact.latestSequenceSentCount &&
    outreachContact.latestSequenceTotalCount &&
    outreachContact.latestSequenceSentCount <
      outreachContact.latestSequenceTotalCount;

  let threadUrl = outreachContact?.lastGmailThreadId
    ? `${outreachContact?.id}/email/${outreachContact?.lastGmailThreadId}`
    : isScheduled
      ? `${outreachContact?.id}/scheduled`
      : `${outreachContact?.id}/email`;

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ cursor: "pointer", my: 0.5, minWidth: "160px" }}
    >
      {selectedView?.name !== "Collaboration" &&
        outreachContact.hasResponded && (
          <Grid item container justifyContent="center" xs={12}>
            <MoveBrandToButton outreachContact={outreachContact} />
          </Grid>
        )}
      {(selectedView?.name === "Collaboration" ||
        !outreachContact.hasResponded) && (
        <>
          {outreachContact.status !== OutreachContactStatus.bounced && (
            <Grid item container justifyContent="center" xs={12}>
              <Button
                tabIndex={-1}
                size="small"
                variant="text"
                color="secondary"
                onClick={() => {
                  if (selectedView?.name === "Collaboration") {
                    goToThread(
                      threadUrl,
                      "Tracking Table Action Pressed",
                      false,
                      { button: "Respond Button" },
                    );
                  } else if (isScheduled || inProgress) {
                    goToThread(
                      threadUrl,
                      "Tracking Table Action Pressed",
                      false,
                      { button: "Send Now Button" },
                    );
                  } else {
                    goToThread(
                      `${threadUrl}?display_followup_templates=${true}`,
                      "Tracking Table Action Pressed",
                      false,
                      { button: "Send Follow-up Button" },
                    );
                  }
                }}
                sx={styles.smallButton}
                disableElevation
              >
                {selectedView?.name === "Collaboration"
                  ? "Respond"
                  : isScheduled || inProgress
                    ? "Send Now"
                    : "Send Follow-up"}
              </Button>
            </Grid>
          )}
          {!outreachContact.hasResponded &&
            outreachContact.bentoBrand?.id &&
            !!outreachContact.latestSequenceSentCount && (
              <Grid item container justifyContent="center" xs={12}>
                <Button
                  tabIndex={-1}
                  size="small"
                  variant="text"
                  color="info"
                  onClick={() => {
                    if (outreachContact.bentoBrand) {
                      handleOpenQuickSendIndividual(
                        outreachContact.bentoBrand.id,
                        "Tracking Page",
                        undefined,
                        true,
                      );
                    }
                  }}
                  sx={styles.smallButton}
                  disableElevation
                >
                  Email New Contact
                </Button>
              </Grid>
            )}
        </>
      )}
    </Grid>
  );
};

export default TableAction;
