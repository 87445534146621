import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext } from "react";
import { BentoBrand, SavedBrand } from "schemas/dashboard";

import { makeDeepCopy } from "./updateLocalState";

export const useCollection = () => {
  const { setTotalsMap, collectionsMap, setCollectionsMap } = useContext(
    SavedBrandCollectionsContext,
  );

  const updateTotal = (
    collectionId: number,
    action: "add" | "remove",
    change = 1,
  ) => {
    setTotalsMap((prev) => {
      if (prev[collectionId]) {
        prev[collectionId] =
          action === "add"
            ? // @ts-ignore: Collection will not be undefined here.
              prev[collectionId] + change
            : // @ts-ignore: Collection will not be undefined here.
              prev[collectionId] - change;
      } else {
        prev[collectionId] = action === "add" ? change : 0;
      }
      return prev;
    });
  };

  const isSavedBrand = (brand: BentoBrand) => {
    const savedBrands = collectionsMap[0] ?? [];
    return (
      savedBrands.findIndex(
        (x) => Number(brand.id) === Number(x?.bentoBrandId),
      ) > -1
    );
  };

  const modifySavedBrands = (
    brand: SavedBrand,
    action: "add" | "remove",
    collectionId: number,
  ) => {
    if (action === "add") {
      _add(brand, collectionId);
    } else {
      _remove(brand);
    }
  };

  const addDraftToCollection = (bentoBrand: BentoBrand) => {
    const collectionId = bentoBrand?.savedBrandCollectionId || 0;
    _modifyProperty(collectionId, bentoBrand, "hasOutreachDraft", true, true);
  };

  const removeDraftFromCollection = (
    collectionId: number,
    bentoBrand: BentoBrand,
  ) => {
    _modifyProperty(collectionId, bentoBrand, "hasOutreachDraft", false, false);
  };

  const isDraftBrand = (bentoBrand: BentoBrand) => {
    return Boolean(
      Object.values(collectionsMap)
        .flat()
        .find(
          (sb) =>
            Number(sb?.bentoBrandId) === Number(bentoBrand.id) &&
            sb?.bentoBrand.hasOutreachDraft === true,
        ),
    );
  };

  const moveAllToList = (brands: BentoBrand[], newCollectionId: number) => {
    setCollectionsMap((prev) => {
      const copy = makeDeepCopy(prev);
      const savedBrands = brands?.map((b) => ({
        bentoBrand: b,
        bentoBrandId: b.id,
      }));
      copy[newCollectionId] = savedBrands;
      updateTotal(newCollectionId, "add", brands?.length);
      return copy;
    });
  };

  const _add = (brand: SavedBrand, collectionId: number) => {
    setCollectionsMap((prev) => {
      const copy = makeDeepCopy(prev);
      const index = prev[collectionId]?.findIndex(
        (b: SavedBrand) =>
          Number(b.bentoBrandId) === Number(brand.bentoBrandId),
      );
      const exists = index === 0 || (index && index > -1);
      if (!exists) {
        copy[collectionId]?.push(brand);
      }
      return copy;
    });
    updateTotal(collectionId, "add");
  };

  const _remove = (brand: SavedBrand) => {
    setCollectionsMap((prev) => {
      const copy = makeDeepCopy(prev);
      // Remove from All Saved
      const index = copy[0]?.findIndex(
        (b: SavedBrand) =>
          Number(b.bentoBrandId) === Number(brand.bentoBrandId),
      );
      if (index > -1) {
        copy[0].splice(index, 1);
        updateTotal(0, "remove");
      }
      // Remove from all other list
      const { customCollectionId } = _findInCollection(brand.bentoBrand);
      if (customCollectionId) {
        const sbIndex = copy[customCollectionId]?.findIndex(
          (x: SavedBrand) =>
            Number(x.bentoBrandId) === Number(brand.bentoBrandId),
        );
        copy[customCollectionId].splice(sbIndex, 1);
        updateTotal(customCollectionId, "remove");
      }
      return copy;
    });
  };

  const _findInCollection = (bentoBrand: BentoBrand) => {
    let indexOfCustomCollection: number | undefined = -1;
    let customCollectionId = Object.values(collectionsMap)
      .flat()
      .find(
        (sb) =>
          Number(sb?.bentoBrandId) === Number(bentoBrand.id) &&
          sb?.bentoBrand.savedBrandCollectionId !== 0,
      )?.bentoBrand?.savedBrandCollectionId;
    if (customCollectionId) {
      indexOfCustomCollection = collectionsMap[customCollectionId]?.findIndex(
        (sb) => Number(sb?.bentoBrandId) === Number(bentoBrand.id),
      );
    }
    return { indexOfCustomCollection, customCollectionId };
  };

  const _modifyProperty = (
    collectionId: number,
    bentoBrand: BentoBrand,
    property: string, // a key such as 'hasOutreachDraft'
    value: any, // value for that key,
    addToList = true,
  ) => {
    setCollectionsMap((prev) => {
      const copy = makeDeepCopy(prev);
      const index = prev[collectionId]?.findIndex(
        (b: SavedBrand) => Number(b.bentoBrandId) === Number(bentoBrand.id),
      );
      if (index !== -1 && index !== undefined) {
        copy[collectionId][index]["bentoBrand"][property] = value;
        if (collectionId === 0) {
          const { indexOfCustomCollection, customCollectionId } =
            _findInCollection(bentoBrand);
          if (
            customCollectionId &&
            indexOfCustomCollection &&
            indexOfCustomCollection > -1
          ) {
            copy[customCollectionId][indexOfCustomCollection]["bentoBrand"][
              property
            ] = value;
          }
        }
      } else if (addToList) {
        copy[collectionId].push({
          bentoBrand: { ...bentoBrand, hasOutreachDraft: true },
          bentoBrandId: bentoBrand.id,
        });
        updateTotal(collectionId, "add");
      }
      return copy;
    });
  };

  return {
    updateTotal,
    isSavedBrand,
    modifySavedBrands,
    addDraftToCollection,
    removeDraftFromCollection,
    isDraftBrand,
    moveAllToList,
  };
};
