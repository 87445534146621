import {
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { useContext, useRef, useState } from "react";
import { AutomatedContactProperty, OutreachContact } from "schemas/dashboard";

import { SelectOption } from "features/Influencer/Tracking/schema";

import { buttonStyles as styles } from "./styles";

export default function MoveBrandToButton({
  outreachContact,
}: {
  outreachContact: OutreachContact;
}) {
  const {
    selectedView,
    setOutreachContacts,
    updateOutreachContactPropertyValues,
    trackTable,
  } = useContext(ContactViewContext);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const handleToggle = () => {
    trackTable("Tracking Table Action Pressed", {
      action: "Move To Action Button",
      "Outreach Contact ID": outreachContact?.id,
      "Outreach Contact Email": outreachContact?.email,
    });
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const stageProperty = selectedView?.contactViewProperties.find(
    (p) =>
      p.contactProperty?.automatedProperty === AutomatedContactProperty.stage,
  );

  if (!stageProperty || !stageProperty.contactPropertyId) {
    return <></>;
  }

  const options = stageProperty?.contactProperty?.selectOptions?.filter(
    (o) =>
      stageProperty.contactPropertyId &&
      o.id !==
        outreachContact.propertyValues?.[stageProperty.contactPropertyId],
  );

  const changeStage = (option: SelectOption) => {
    trackTable("Move To Action Changed Stage", {
      newStage: option.name,
      "Outreach Contact ID": outreachContact?.id,
      "Outreach Contact Email": outreachContact?.email,
    });

    setOutreachContacts((prev) => {
      let pValues;
      const copy = prev.map((contact) => {
        if (contact.id !== outreachContact.id) {
          return contact;
        }
        pValues = { ...contact.propertyValues };
        if (stageProperty.contactPropertyId) {
          pValues[stageProperty.contactPropertyId] = option.id;
        }

        return {
          ...contact,
          propertyValues: pValues,
        };
      });
      if (pValues && stageProperty.contactPropertyId && outreachContact.id) {
        updateOutreachContactPropertyValues(
          outreachContact.id,
          stageProperty.contactPropertyId,
          pValues[stageProperty.contactPropertyId],
        );
      }
      if (selectedView?.name === "All") {
        return copy;
      } else {
        return copy.filter((c) => c.id !== outreachContact.id);
      }
    });
  };

  return (
    <>
      <Button
        ref={anchorRef}
        tabIndex={-1}
        size="small"
        variant="text"
        color="primary"
        sx={styles.smallButton}
        id="move-brand-to-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        Move Brand To
      </Button>
      <Popper
        open={open}
        ref={popperRef}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        sx={{ zIndex: 2 }}
      >
        <Paper sx={{ fontSize: 12, p: 1, background: "white" }} elevation={2}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              id="move-brand-to-menu"
              aria-labelledby="move-brand-to-button"
            >
              {options?.map((option) => (
                <MenuItem key={option.id} onClick={() => changeStage(option)}>
                  {option.name}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
}
